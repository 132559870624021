import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { List, ListItemAvatar, Avatar, ListItemText, ListItemButton } from '@mui/material';
import { deepPurple, deepOrange } from '@mui/material/colors';
import WorkIcon from '@mui/icons-material/Work';

const WorkSchedulesList = ({ workSchedules, onSelected }) => {
  // Basic validation to check if workSchedules is an array
  if (!Array.isArray(workSchedules)) {
    return <div>Error: workSchedules is not an array</div>;
  }

  return (
    <List>
      {workSchedules.map((workSchedule) => {
        // Check for required workSchedule properties
        if (!workSchedule.Codigo || !workSchedule.DiasLaborales) {
          return null; // or handle the error as needed
        }

        return (
          <ListItemButton 
            id={workSchedule.Codigo} 
            key={workSchedule.Codigo} 
            onClick={() => onSelected(workSchedule.Codigo)}
            aria-label={`Select ${workSchedule.DiasLaborales}`}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor:deepOrange[700] }} >
                <WorkIcon></WorkIcon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText 
              primary={workSchedule.Codigo} 
              secondary={`${workSchedule.DiasLaborales} I:${workSchedule.HoraDeInicio} S:${workSchedule.HoraDeSalida}`} 
            />
          </ListItemButton>
        );
      })}
    </List>
  );
};

// PropTypes for type checking
WorkSchedulesList.propTypes = {
  workSchedules: PropTypes.arrayOf(PropTypes.shape({
    Codigo: PropTypes.string.isRequired,
    DiasLaborales: PropTypes.string.isRequired,
    HoraDeInicio: PropTypes.number.isRequired,
    HoraDeSalida: PropTypes.number.isRequired
  })).isRequired,
  onSelected: PropTypes.func.isRequired
};

export default WorkSchedulesList;
