import React, { useState, createContext, useContext } from 'react';

// Create a Context for navigation
export const NavigationContext = createContext();

export const useNavigation = () => useContext(NavigationContext);

// Navigation Provider Component
export const NavigationProvider = ({ children }) => {
    const [currentView, setCurrentView] = useState(null);
    const [lastView, setLastView] = useState([]);
    const [alertToDisplay, setAlertToDisplay] = useState({Open: false, Title: "Alerta", Message: "", OKCallback: null, ShowOkCancel: false});

    const navigateTo = (view, allowBack = false) => {
        if (currentView && allowBack)
        {
            setLastView([...lastView, currentView]);
        }
        setCurrentView(view);
    };
    const navigateBack = () => {
        if(lastView.length > 0)
        {
            const newLastView = lastView.slice(0, lastView.length - 1);
            // Utilizamos el último elemento para actualizar el currentView
            const prevView = lastView[lastView.length - 1];
            setCurrentView(prevView);
            setLastView(newLastView);
        }
    }

    const showAlert = (title, message, okCallback, showOkCancel) =>
    {
        setAlertToDisplay({Open: true, Title: title, Message: message, OKCallback: okCallback, ShowOkCancel: showOkCancel});
    }

    return (
        <NavigationContext.Provider value={{ currentView, navigateTo, navigateBack, alertToDisplay, showAlert }}>
            {children}
        </NavigationContext.Provider>
    );
};
