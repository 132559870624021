// src/lib/ApiContext.js
import React, { createContext, useContext } from 'react';
import ApiManager from './ApiManager'; // Importa tu clase Api

const ApiContext = createContext();

export const ApiProvider = ({ children, user, session, onLogoutCallback }) => {
  const apiInstance = new ApiManager(user, session, onLogoutCallback);

  return (
    <ApiContext.Provider value={apiInstance}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApiManager = () => {
  const apiInstance = useContext(ApiContext);
  if (!apiInstance) {
    throw new Error('useApi debe ser utilizado dentro de un ApiProvider');
  }
  return apiInstance;
};
