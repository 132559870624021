import React, { useState, useEffect }  from 'react';
import { useApiManager } from 'lib/ApiContext';
import { styled, useTheme } from '@mui/material/styles';
import Title from 'components/Title';
import Box from '@mui/material/Box';
import {Grid, Button} from '@mui/material';
import WorkSchedulesList from 'components/WorkSchedulesList';
import WorkSchedule from './WorkSchedule';
import { useNavigation } from 'lib/NavigationContext';
import NewWorkSchedule from './NewWorkSchedule';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const WorkSchedules = () => {
    const api = useApiManager();
    const { navigateTo } = useNavigation();
    const [workSchedules, setWorkSchedules] = useState([]);

    useEffect(()=> {
        api.GetWorkSchedules().then( r => setWorkSchedules(r));
    },[]);

    const onCreate = () => {
      navigateTo(<NewWorkSchedule />);
    }
    const onSelectedSchedule = (codigo) => {
      navigateTo(<WorkSchedule codigo={codigo}/>);
    }

    return  <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2} justifyContent="space-between"> 
        <Grid item xs={8}><Title>Puestos De Trabajo</Title></Grid> 
          <Grid item xs={1}>
            <Fab color="primary" aria-label="add"  onClick={onCreate}>
              <AddIcon />
            </Fab>
        </Grid>
      </Grid>
      <WorkSchedulesList workSchedules = {workSchedules} onSelected = {onSelectedSchedule}></WorkSchedulesList>
    </Box>
}

export default WorkSchedules;