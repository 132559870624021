import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function AlertDialog({dialogContent}) {
  const [content, setContent] = React.useState(dialogContent);
  
  React.useEffect(()=>{
    setContent(dialogContent);
  }, [dialogContent])

  const cleanContent = () =>
  {
    setContent({Open: false, Title: "Alerta", Message: "", OKCallback: null, ShowOkCancel: false});
  }

  const handleClose = () => {
    cleanContent();
  };

  const handleAccept = () => {
    setContent( { ...content, Open:false });
    if (content.OKCallback) {
      content.OKCallback();
    }
    cleanContent();// clean content to avoid it reopen.
  };

  return (
    <React.Fragment>
      
      <BootstrapDialog
        open={content.Open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
      >
        <DialogTitle id="alert-dialog-title">
          {content.Title}
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content.Message}
          </DialogContentText>
        </DialogContent>
        {content.ShowOkCancel ?
        <DialogActions>
          <Button variant='contained' sx={{margin:2}}  onClick={handleAccept}>Si</Button> 
          <Button variant='contained' sx={{margin:2}}  onClick={handleClose} autoFocus>No</Button>
        </DialogActions>
        :
        <DialogActions sx={{ justifyContent: 'center' }} >
          <Button variant='contained' sx={{margin:2}}  onClick={handleAccept}>OK</Button>
        </DialogActions> 
        }
      </BootstrapDialog>
    </React.Fragment>
  );
}