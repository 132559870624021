import React, { useState, useEffect }  from 'react';
import { useApiManager } from 'lib/ApiContext';
import { styled, useTheme } from '@mui/material/styles';
import Title from 'components/Title';
import Box from '@mui/material/Box';
import {Grid, Button} from '@mui/material';
import IncapacitieGrid from 'components/IncapacitieGrid';
import IncapacitiesList from 'components/IncapacitiesList';
import Incapacitie from './Incapacitie';
import NewIncapacitie from './NewIncapacitie';
import {useNavigation} from '../../lib/NavigationContext'

const Incapacities = () => {
    const api = useApiManager();
    const { navigateTo } = useNavigation();
    const [incapacities, setIncapacities] = useState([]);

    useEffect(()=> {
        api.GetIncapacities().then( r => setIncapacities(r));
    },[]);

    const onCreate = () => {
      navigateTo(<NewIncapacitie navigateTo={navigateTo}/>);
    }
    const onSelectedSchedule = (cedula) => {
      //navigateTo(<Incapacitie cedula={cedula}/>);
    }

    return  <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2} justifyContent="space-between"> 
        <Grid item xs={8}><Title>Incapacidades</Title></Grid> 
        <Grid item xs={1}><Button variant="contained" onClick={onCreate}>+ Add</Button></Grid>
      </Grid>
      <IncapacitieGrid incapacities = {incapacities} onSelected = {onSelectedSchedule}></IncapacitieGrid>
    </Box>
}

export default Incapacities;