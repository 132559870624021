import React, { useState, useEffect } from 'react';
import { useApiManager } from 'lib/ApiContext';
import Box from '@mui/material/Box';
import { Stack, Typography, Avatar, Divider, Button } from '@mui/material';
import { deepPurple, grey, deepOrange } from '@mui/material/colors';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Exception = ({ IDException, navigateTo }) => {
    const api = useApiManager();
    const [exception, setException] = useState(null);
    const formatter = new Intl.NumberFormat('es-CR', { style: 'currency', currency: 'CRC' });

    useEffect(() => {
        api.GetExceptionById(IDException).then(r => setException(r));
    }, [IDException]);

    return <Box>
        {exception == null ?
            <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Skeleton variant="circular" width={70} height={70} />
                    <Box>
                        <Skeleton variant="rectangular" width={210} height={60} />
                        <Skeleton variant="rectangular" width={150} height={30} />
                    </Box>
                </Stack>
                 <Skeleton variant="rectangular" width={210} height={500} />
            </Stack>
            :
            <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar sx={{ bgcolor:deepOrange[700] }} />
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant="h4" >
                            {exception.IDException }
                        </Typography>
                        <Typography variant="h5" style={{ color: grey[500] }}>
                        {`${exception.Cedula} I:${exception.Fecha} S:${exception.Horas}`} 
                        </Typography>
                    </Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="flex-end">
                <Paper elevation={3} sx={{ textAlign: 'left', padding: 1,width:300 }}>
                    <Typography variant="h6" gutterBottom>
                        {"Datos Generales"}
                    </Typography>
                    <Stack direction="column" spacing={1} sx={{ padding: 1 }}>
                        <Typography variant="body1" style={{ color: 'black' }}>
                            {"Cedula:"}
                        </Typography>
                        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', color: grey[700] }}>
                            <CalendarMonthIcon fontSize="small" sx={{marginRight:0.5}} />
                            {exception.Cedula}
                        </Typography>
                        <Divider orientation='horizontal' flexItem></Divider>
                        <Typography variant="body1" style={{ color: 'black' }} >
                            {"Hora De Inicio: "}
                        </Typography>
                        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', color: grey[700] }}>
                            <AccessTimeIcon fontSize="small" sx={{marginRight:0.5}}/>
                            {` ${exception.Fecha} Horas`}
                        </Typography>
                        <Divider orientation='horizontal' flexItem></Divider>
                        <Typography variant="body1" style={{ color: 'black' }}>
                            {"Hora De Salida:"}
                        </Typography>
                        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', color: grey[700] }}>
                            <AccessTimeIcon fontSize="small" sx={{marginRight:0.5}}/>
                            {` ${exception.Horas} Horas`}
                        </Typography>
                        <Divider orientation='horizontal' flexItem></Divider>
                        <Typography variant="body1" style={{ color: 'black' }}>
                            {"Puesto:"}
                        </Typography>
                    </Stack>
                </Paper>
                </Stack>
            </Stack>
        }
    </Box>
}

export default Exception;
