import React, { useState, useEffect } from 'react';
import { useApiManager } from 'lib/ApiContext';
import { Grid, Box, Stack, Breadcrumbs, Typography, Avatar, Divider, Button, MenuItem, Chip, emphasize, Backdrop, CircularProgress, FormControlLabel, Switch } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import { styled, useTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Ajuste from './Ajuste';
import { CPTextField, StyledBreadcrumb, CPSelect } from 'components/CPStyles';
import Ajustes from './Ajustes';
import Employee from 'pages/employeesPages/Employee';
import { useNavigation } from '../../lib/NavigationContext'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { grey, deepPurple } from '@mui/material/colors';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';


const EditAjuste = ({ employee, oldAjuste}) => {

    const api = useApiManager();
    const theme = useTheme();
    const { navigateTo } = useNavigation();
    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    const [ajuste, setAjuste] = useState(oldAjuste);

    const [errors, setErrors] = useState({});
    useEffect(() => {
        if (oldAjuste) {
            setAjuste(oldAjuste);
        }
    }, [oldAjuste]);

    const handlesChange = (e) => {
        const { name, value } = e.target;
        if (name == "Cantidad")
        {
            setAjuste({ ...ajuste, [name]: new Number(value) });
        }
        else {
            setAjuste({ ...ajuste, [name]: value });
        }
    };

    const handlesBlur = (e) => {
        const { name, value } = e.target;
        if (name != 'Cantidad') {
            if (value.trim() === '') {
                setErrors({ ...errors, [name]: 'Este campo es requerido' });
            } else {
                setErrors({ ...errors, [name]: undefined });
            }
        }
        else {
            if (value <= 0) {
                setErrors({ ...errors, [name]: 'El valor no puede ser 0' });
            }
            else {
                setErrors({ ...errors, [name]: undefined });
            }
        }
    };
    const validatesFields = () => {
        let newErrors = {};
        for (const key in ajuste) {
            if (key != 'Cantidad') {
                if (typeof ajuste[key] === 'string' && ajuste[key].trim() === '') {
                    newErrors[key] = 'Este campo es requerido';
                }
            }
            else if (ajuste[key] <= 0) {
                newErrors[key] = 'El valor no puede ser 0';
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == "FechaInicio")
        {
            setAjuste({ ...ajuste, [name]: new Number(value) });
        }
        else {
            setAjuste({ ...ajuste, [name]: value });
        }
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
            if (value.trim() === '') {
                setErrors({ ...errors, [name]: 'Este campo es requerido' });
            } else {
                setErrors({ ...errors, [name]: undefined });
            }
    };

    const validateFields = () => {
        let newErrors = {};
        for (const key in ajuste) {
                if (typeof ajuste[key] === 'string' && ajuste[key].trim() === '') {
                    newErrors[key] = 'Este campo es requerido';
                }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        // Call your API to add the new ajuste
        // Example: await api.addAjuste(ajuste);
        setOpenBackdrop(true);

        if (!validateFields()) {
            setOpenBackdrop(false)

        }
        else {
            api.CreateAjuste(ajuste).catch((e) => {
                setOpenBackdrop(false); console.error(e);
            })
                .then(navigateTo(<Employee cedula={ajuste.Cedula} />));
        }
    };

    const handleNavigateBack = (target) => {
        navigateTo(<Employee cedula={ajuste.Cedula} />);
    }

    return <form>
        <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
                component="a"
                href="#"
                onClick={() => handleNavigateBack("dashboard")}
                label="Principal"
                icon={<DashboardIcon fontSize="small" />}
            />
            <StyledBreadcrumb component="a" href="#" label="Excepciones" onClick={() => handleNavigateBack("Excepciones")} />
            <StyledBreadcrumb
                label="Editar Ajuste"
            />
        </Breadcrumbs>
        <Paper elevation={3} sx={{ textAlign: 'left', padding: 1, margin: 2 }}>
            <Typography variant="h4" color="primary" gutterBottom>
                {"Editar Ajuste"}
            </Typography>
            <br />
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12}>
                    <Stack direction="row" spacing={1} >
                        <Avatar sx={{ bgcolor: deepPurple[500], width: 40, height: 40 }}>
                            {employee.Nombre.substring(0, 1)}
                        </Avatar>
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography variant="h5" >
                                {employee.Nombre + " " + employee.Apellidos}
                            </Typography>
                            <Typography variant="subscript1" style={{ color: grey[500] }}>
                                {employee.Cargo}
                            </Typography>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <CPTextField
                        required
                        id="Cantidad"
                        name="Cantidad"
                        label="Monto"
                        value={ajuste.Cantidad}
                        onChange={handlesChange}
                        variant="filled"
                        fullWidth
                        type='number'
                    />
                </Grid>
                <Grid item xs={4} sm={6}>
                    <CPTextField
                        required
                        id="Razon"
                        name="Razon"
                        label="Razon"
                        value={ajuste.Razon}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors["Razon"]}
                        helperText={errors["Razon"]}
                        defaultValue=""
                        variant="filled"
                        color='secondary'
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="FechaInicio"
                            fullWidth
                            required
                            value={dayjs(ajuste.FechaInicio)}
                            onChange={(newValue) => {
                                setAjuste({ ...ajuste, FechaInicio: newValue.format("YYYY-MM-DD") });
                            }}
                            renderInput={(params) => (
                                <CPTextField
                                    {...params}
                                    required
                                    fullWidth
                                    error={!!errors["FechaInicio"]}
                                    helperText={errors["FechaInicio"]}
                                    variant="filled"
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={4} lg={2} >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="FechaFinal"
                            fullWidth
                            required
                            value={dayjs(ajuste.FechaFinal)}
                            onChange={(newValue) => {
                                setAjuste({ ...ajuste, FechaFinal: newValue.format("YYYY-MM-DD") });
                            }}
                            renderInput={(params) => (
                                <CPTextField
                                    {...params}
                                    required
                                    fullWidth
                                    error={!!errors["FechaFinal"]}
                                    helperText={errors["FechaFinal"]}
                                    variant="filled"
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={1}>
                <Typography variant="body1" >
                    {'Porcentual'}
                </Typography>
                <Switch 
                            checked={ajuste.Porcentual} // controlled component
                            onChange={(e) => {
                                setAjuste({ ...ajuste, Porcentual: e.target.checked });
                            }} 
                            inputProps={{ 'aria-label': 'controlled' }} 
                            color="secondary"
                        />
                </Grid>
                
            </Grid>
            <br />
            <Divider orientation='horizontal' />
            <Button onClick={handleSubmit} variant="contained" sx={{ margin: 2 }}>Editar</Button>
        </Paper>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </form>
}

export default EditAjuste;