import React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { DataGrid, GridRowsProp, GridColDef, GridActionsCell, GridActionsCellItem} from '@mui/x-data-grid';
import { List, ListItemAvatar, Avatar, ListItemText, ListItemButton } from '@mui/material';
import { deepPurple, deepOrange } from '@mui/material/colors';
import Incapacities from 'pages/incapcitiesPages/Incapacities';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigation} from 'lib/NavigationContext'
import EditIncapacitie from 'pages/incapcitiesPages/EditIncapacitie';



const IncapacitieGrid = ({ incapacities, onSelected, employee, onDeleted }) => {
  const { navigateTo } = useNavigation(); 
  const deleteAction = (id) => {
    if (onDeleted)
        onDeleted(id);
  }
  const selectAction = (id) => {
      if (onSelected)
          onSelected(id);
  } 
  const columns = () => {
      const cols = [
    
    { field: 'FechaInicio', headerName: 'Inicio', width: 150, 
    valueGetter: (params) => {
      if (params.value) {
        return new Date(params.value).toLocaleDateString("es-CR");
      }
      
      return "-";
    } },
    { field: 'FechaFinal', headerName: 'Fin', width: 150, 
    valueGetter: (params) => {
      if (params.value) {
        return new Date(params.value).toLocaleDateString("es-CR");
      }
      
      return "-";
    },
    },
    { field: 'PorCaja', headerName: 'Por Caja', width: 80, 
    valueGetter: (params) => {
      if (params.value != undefined) {
        return params.value? "Sí" : "No";
      }
    
      return "No";
    } },
    {
      field: 'actions',
      width: 30,
      type: 'actions',
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            icon={<CreateIcon/>}
            label="Edit"
            onClick={() => {
              navigateTo(<EditIncapacitie employee={employee} oldIncapacitie={row} />)
            }}
          />,
        ];
      },
    },
    {
      field: 'action',
      width: 30,
      type: 'actions',
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon/>}
            label="DELETE"
            onClick={() => {
              deleteAction (id)
            }}
          />,
        ];
      },
  },
  ];

  return cols;

  }
// Basic validation to check if incapacities is an array
  if (!Array.isArray(incapacities)) {
    return <div>Error: incapacities is not an array</div>;
  }

  return (
    <DataGrid rows={incapacities} columns={columns()} 
    getRowId={(row)=>{ return row?row.incapacityID :0;}} sx={{height:250}}
    initialState={{
      sorting: {
        sortModel: [{ field: 'FechaFinal', sort: 'desc' }],
      },
    }}
    />
  );
};

// PropTypes for type checking
IncapacitieGrid.propTypes = {
  incapacities: PropTypes.arrayOf(PropTypes.shape({
    incapacityID : PropTypes.string.isRequired,
    Cedula: PropTypes.string.isRequired,
    FechaFinal: PropTypes.string.isRequired,
    FechaInicio: PropTypes.string.isRequired,
    PorCaja: PropTypes.bool.isRequired
  })).isRequired,
  onSelected: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired
};

export default IncapacitieGrid;
