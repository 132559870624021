import React, { useState, useEffect }  from 'react';
import { useApiManager } from 'lib/ApiContext';
import { styled, useTheme } from '@mui/material/styles';
import Title from 'components/Title';
import Box from '@mui/material/Box';
import {Grid, Button} from '@mui/material';
import ExceptionGrid from 'components/ExceptionGrid';
import ExceptionsList from 'components/ExceptionsList';
import Exception from './Exception';
import NewException from './NewException';
import {useNavigation} from '../../lib/NavigationContext'

const Exceptions = () => {
    const api = useApiManager();
    const { navigateTo } = useNavigation();
    const [exceptions, setExceptions] = useState([]);

    useEffect(()=> {
        api.GetExceptions().then( r => setExceptions(r));
    },[]);

    const onCreate = () => {
      navigateTo(<NewException navigateTo={navigateTo}/>);
    }
    const onSelectedSchedule = (cedula) => {
      navigateTo(<Exception cedula={cedula}/>);
    }

    return  <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2} justifyContent="space-between"> 
        <Grid item xs={8}><Title>Ajustes</Title></Grid> 
        <Grid item xs={1}><Button variant="contained" onClick={onCreate}>+ Add</Button></Grid>
      </Grid>
      <ExceptionGrid exceptions = {exceptions} onSelected = {onSelectedSchedule}></ExceptionGrid>
    </Box>
}

export default Exceptions;