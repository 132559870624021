import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { List, ListItemAvatar, Avatar, ListItemText, ListItemButton } from '@mui/material';
import { deepPurple, grey } from '@mui/material/colors';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const EmployeeList = ({ employees, onSelected }) => {
  // Basic validation to check if employees is an array
  if (!Array.isArray(employees)) {
    return <div>Error: employees is not an array</div>;
  }

  return (
    <List>
      {
      employees.length == 0 ? 
      <Stack orientation="row">
        <Skeleton variant="circular" width={40} height={40} />
        <Stack orientation="column">
          <Skeleton variant="rectangular" width={210} height={60} />
          <Skeleton variant="rounded" width={210} height={60} />
        </Stack>
      </Stack>
      :
      employees.map((employee) => {
        // Check for required employee properties
        if (!employee.Cedula || !employee.Nombre || !employee.Apellidos || !employee.Cargo) {
          return null; // or handle the error as needed
        }

        return (
          <ListItemButton 
            id={employee.Cedula} 
            key={employee.Cedula} 
            onClick={() => onSelected(employee.Cedula)}
            aria-label={`Select ${employee.Nombre}`}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: (employee.Activo ? deepPurple[500] : grey[400]) }} >
                {employee.Nombre.substring(0,1)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText 
              primary={`${employee.Nombre} ${employee.Apellidos}`} 
              secondary={employee.Cargo} 
            />
          </ListItemButton>
        );
      })}
    </List>
  );
};

// PropTypes for type checking
EmployeeList.propTypes = {
  employees: PropTypes.arrayOf(PropTypes.shape({
    Cedula: PropTypes.string.isRequired,
    Nombre: PropTypes.string.isRequired,
    Apellidos: PropTypes.string.isRequired,
    Cargo: PropTypes.string.isRequired
  })).isRequired,
  onSelected: PropTypes.func.isRequired
};

export default EmployeeList;
