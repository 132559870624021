import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const Title =  (props) => {
    return (
        <Typography textAlign={'left'} component="h2" variant="h3" color="primary"  gutterBottom >
          {props.children}
        </Typography>
      );
  } 

  export default Title;