import React, { useState, useEffect } from 'react';
import { useApiManager } from 'lib/ApiContext';
import Box from '@mui/material/Box';
import { Breadcrumbs, Stack, Typography, Avatar, Divider, Button } from '@mui/material';
import { deepPurple, grey, deepOrange } from '@mui/material/colors';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import WorkIcon from '@mui/icons-material/Work';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CreateIcon from '@mui/icons-material/Create';
import { color } from '@mui/system';
import { useNavigation } from 'lib/NavigationContext';
import EditWorkSchedule from './EditWorkSchedule';
import { StyledBreadcrumb } from 'components/CPStyles';
import WorkSchedules from './WorkSchedules';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const WorkSchedule = ({ codigo }) => {
    const api = useApiManager();
    const { navigateTo, showAlert } = useNavigation();
    const [workSchedule, setWorkSchedule] = useState({Codigo: "", DiasLaborales:"", HoraDeInicio:0, HoraDeSalida:0});
    const formatter = new Intl.NumberFormat('es-CR', { style: 'currency', currency: 'CRC' });

    useEffect(() => {
        api.GetWorkSchedule(codigo).then(r => setWorkSchedule(r));
    }, []);

    const onEditSchedule = () => {
        navigateTo(<EditWorkSchedule schedule={workSchedule} />)
    }

    const handleNavigateBack = (target) =>
    {
        if (target == "puesto")
        {
          
        }
        else
        {
            navigateTo(<WorkSchedules />);
        }
    }

    const onDeleted = () => {
        showAlert(<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><WarningAmberIcon sx={{color: 'red'}}/>"Cuidado!"</Box>, <Typography>Esta seguro que quiere borrar este el puesto de trabajo "<b style={{color: "blue"}}>{workSchedule.Codigo}</b>"? una ves borrado no se puede recuperar.</Typography>, async ()  => 
        { 
            await api.DeleteWorkSchedule(workSchedule.Codigo);
            navigateTo(<WorkSchedules />);
        }, true);
    }

    return <Box  width="100%">
        {workSchedule == null ?
            <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Skeleton variant="circular" width={70} height={70} />
                    <Box>
                        <Skeleton variant="rectangular" width={210} height={60} />
                        <Skeleton variant="rectangular" width={150} height={30} />
                    </Box>
                </Stack>
                 <Skeleton variant="rectangular" width={210} height={500} />
            </Stack>
            :
            
            <Stack direction="column" spacing={2} width="100%"  >
                 <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                        component="a"
                        href="#"
                        onClick={()=> handleNavigateBack("dashboard")}
                        label="Principal"
                        icon={<DashboardIcon fontSize="small" />}
                    />
                    <StyledBreadcrumb component="a" href="#" label="Puesto" />
                </Breadcrumbs>
                <Stack direction="row" spacing={2} alignItems="center" width={"100%"} justifyContent="space-between">
                    <Stack direction="row" spacing={2} >
                        <Avatar sx={{ bgcolor:deepOrange[700] }} >
                            <WorkIcon></WorkIcon>
                        </Avatar>
                        <Box sx={{ textAlign: 'left' }} md={12}>
                            <Typography variant="h4" >
                                {workSchedule.Codigo}
                            </Typography>
                            <Typography variant="h5" style={{ color: grey[500] }}>
                            {`${workSchedule.DiasLaborales} I:${workSchedule.HoraDeInicio} S:${workSchedule.HoraDeSalida}`} 
                            </Typography>
                        </Box>
                    </Stack>
                    <Button  onClick={onEditSchedule}><CreateIcon/>Editar</Button>
                </Stack>
            
                <Paper elevation={3} sx={{ textAlign: 'left', padding: 1 }}>
                    <Typography variant="h6" gutterBottom>
                        {"Datos Generales1"}
                    </Typography>
                    <Stack direction="column" spacing={1} sx={{ padding: 1 }}>
                        <Typography variant="body1" style={{ color: 'black' }}>
                            {"Dias Laborales:"}
                        </Typography>
                        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', color: grey[700] }}>
                            <CalendarMonthIcon fontSize="small" sx={{marginRight:0.5}} />
                            {workSchedule.DiasLaborales}
                        </Typography>
                        <Divider orientation='horizontal' flexItem></Divider>
                        <Typography variant="body1" style={{ color: 'black' }} >
                            {"Hora De Inicio: "}
                        </Typography>
                        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', color: grey[700] }}>
                            <AccessTimeIcon fontSize="small" sx={{marginRight:0.5}}/>
                            {` ${workSchedule.HoraDeInicio} Horas`}
                        </Typography>
                        <Divider orientation='horizontal' flexItem></Divider>
                        <Typography variant="body1" style={{ color: 'black' }}>
                            {"Hora De Salida:"}
                        </Typography>
                        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', color: grey[700] }}>
                            <AccessTimeIcon fontSize="small" sx={{marginRight:0.5}}/>
                            {` ${workSchedule.HoraDeSalida} Horas`}
                        </Typography>
                        <Divider orientation='horizontal' flexItem></Divider>
                        <Typography variant="body1" style={{ color: 'black' }}>
                            {"Puesto:"}
                        </Typography>
                        <Typography variant="body2" style={{ color: grey[700] }}>
                            {workSchedule.Codigo}
                        </Typography>
                        <Button variant='contained' sx={{backgroundColor:"red"}} onClick={onDeleted} >Borrar</Button>
                    </Stack>
                </Paper>
                
            </Stack>
        }
    </Box>
}

export default WorkSchedule;
