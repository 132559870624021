import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Select, Chip, emphasize, Box, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


import { grey, orange } from '@mui/material/colors';

export const CPTextField = styled(TextField)({
    '& .MuiFilledInput-root': {
        backgroundColor: grey[50],
        borderRadius: '8px', // Rounded border
        border: '1px solid #ced4da', // Add outline border
    },
    '& .MuiFilledInput-root:hover': {
        backgroundColor: 'white', // Optional: Change color on hover
        borderColor: orange[500], // Optional: Change border color on hover
    },
    '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: 'white', // Optional: Change color when focused
        borderColor: '#3f51b5', // Optional: Change border color when focused
    },
    '& .MuiFilledInput-underline:after, & .MuiFilledInput-underline:before': {
        content: 'none', // Removes the underline effect
    },
});

export const CPSelect = styled(Select)({
    '& .MuiSelect-select': {
        backgroundColor: grey[50],
        borderRadius: '8px', // Rounded border
        border: '1px solid #ced4da', // Add outline border
        
    },
    '& .MuiSelect-select:hover': {
        backgroundColor: 'white', // Optional: Change color on hover
        borderColor: orange[500], // Optional: Change border color on hover
    },
    '& .MuiSelect-select.Mui-focused': {
        backgroundColor: 'white', // Optional: Change color when focused
        borderColor: '#3f51b5', // Optional: Change border color when focused
    },
    '& .MuiFilledSelect-underline:after, & .MuiFilledSelect-underline:before': {
        content: 'none', // Removes the underline effect
    },
    '& .MuiSelect-select:focus': {
        borderColor: '#3f51b5',
        boxShadow: '0 0 0 1px #3f51b5',
      }
});


export const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
}); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  export const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.augmentColor,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.footer}`]: {
        backgroundColor: theme.palette.augmentColor,
        color: theme.palette.common.white,
      },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: theme.palette.common.black
    },
  }));
  
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '&:last-of-type': {
        backgroundColor: theme.palette.augmentColor, 
    },
  }));
  export const StyledTableRow2 = styled(TableRow)(({ theme }) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
      backgroundColor: theme.palette.augmentColor,
      border: 0,
    },
  }));

 
  
