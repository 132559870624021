import React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { DataGrid, GridRowsProp, GridColDef, GridActionsCell, GridActionsCellItem} from '@mui/x-data-grid';
import { List, ListItemAvatar, Avatar, ListItemText, ListItemButton } from '@mui/material';
import { deepPurple, deepOrange } from '@mui/material/colors';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigation} from 'lib/NavigationContext'
import PayRollPage from 'pages/Reports/PayRollPage';
import { getValue } from '@mui/system';
import PayBonusPage from "pages/Reports/PayBonusPage";
import { Box, Typography } from '@mui/material';

const dateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric"
  };
const ChrismasBonusGrid = ({ employee, chrismasBonus, onSelected, onDeleted }) => {

   const formatter = new Intl.NumberFormat('es-CR', { style: 'currency', currency: 'CRC' });

  // Basic validation to check if payments is an array
  const { navigateTo } = useNavigation();
  const deleteAction = (id) => {
    if (onDeleted)
        onDeleted(id);
  }
  const selectAction = (id) => {
    if (onSelected)
        onSelected(id);
  }
  const openDetail = (row) => {
    // Aquí puedes navegar a otra página o mostrar más detalles del aguinaldo seleccionado
    navigateTo(<PayBonusPage Bonus={row} />, true);
    // console.log(row);
};
  const columns = () => {
    const cols = [
        
        { field: 'Periodo', headerName: 'Periodo', width: 100 },
        { field: 'FechaDePago', headerName: 'Fecha de Pago', width: 150 },
        //{ field: 'EstadoPago', headerName: 'Estado', width: 100 },
        {
          field: 'notification',
          headerName: 'Estado',
          width: 100,
          type: 'actions',
          getActions: ({ id, row }) => {
              return[
              <Box> {row.EstadoPago == 0? ""  : <Box sx={{
                  border: 1,
                  borderRadius: "8px",
                  backgroundColor: "green",
                  color: "white",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  fontSize: 11,
                  textAlign: "center",
                  width: 60,
                  fontFamily: "Arial",
                  fontWeight: "fontWeightBold"

              }}>Pagado</Box>  } </Box>
              ]
          }
        },
        {field: 'Total', headerName: 'Total', width: 150,
                valueGetter: (params) => formatter.format(params.value || 0)
        },
        {
          field: 'actions',
          width: 30,
          type: 'actions',
          getActions: ({ id, row }) => {
            return [
              <GridActionsCellItem
                icon={<CreateIcon/>}
                label="Edit"
                onClick={() => {
                  navigateTo(<PayBonusPage Bonus={row} />, true);
                }}
              />,
            ];
          },
        },
        {
          field: 'action',
          width: 30,
          type: 'actions',
          getActions: ({ id, row }) => {
            return [
              <GridActionsCellItem
                icon={<DeleteIcon/>}
                label="DELETE"
                onClick={() => openDetail(row)}
              />,
            ];
          },
        }, 
    ];
    
    return cols;

}

    // Basic validation to check if chrismasBonus is an array
    if (!Array.isArray(chrismasBonus)) {
        return <div>Error: chrismasBonus is not an array</div>;
    }

    return (
        <DataGrid rows={chrismasBonus} columns={columns()}
            getRowId={(row) => { return row ? row.IDChristmasBonus : 0; }} sx={{ height: 250 }}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'Fecha', sort: 'desc' }],
                },
            }}
        />
    );

  
};

// PropTypes for type checking
ChrismasBonusGrid.propTypes = {
  exceptions: PropTypes.arrayOf(PropTypes.shape({
    IDChristmasBonus: PropTypes.string.isRequired,
    companyID: PropTypes.number.isRequired,
    FechaDePago: PropTypes.string.isRequired,
    Periodo: PropTypes.number.isRequired,
    EstadoPago: PropTypes.number.isRequired,
    Total: PropTypes.number.isRequired,
    cedula: PropTypes.string.isRequired,
  })).isRequired,
  onSelected: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired
};

export default ChrismasBonusGrid;