import React, { useState, useEffect } from 'react';
import { useApiManager } from 'lib/ApiContext';
import { useNavigation } from '../../lib/NavigationContext';
import PeopleIcon from '@mui/icons-material/People';
import EmailIcon from '@mui/icons-material/Email';
import { Grid, Box, Stack, Breadcrumbs, Typography, Paper, Button, Skeleton, Backdrop, CircularProgress } from '@mui/material';
import { StyledBreadcrumb, StyledTableCell, StyledTableRow } from 'components/CPStyles';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Employee from 'pages/employeesPages/Employee';

const PayBonusPage = ({ employee, Bonus }) => {
    const api = useApiManager();
    const { navigateTo, navigateBack } = useNavigation();
    const [bonus, setBonus] = useState(Bonus || null);
    const [company, setCompany] = useState(null);
    const [userData, setUserData] = useState(null);
    const [openBackdrop, setOpenBackdrop] = useState(false); // Para el loading
    const formatter = new Intl.NumberFormat('es-CR', { style: 'currency', currency: 'CRC' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = await api.GetCurrentUserCompany();
                setUserData(user);
                const companyData = await api.GetCompany(user.companyID);
                setCompany(companyData);

                if (!Bonus) {
                    // Si no hay Bonus, cargar la información del bonus según el empleado
                    const fetchedBonus = await api.GetChristmasBonusByCedula(employee.Cedula); // Asume que existe una función para esto
                    setBonus(fetchedBonus);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [Bonus, employee, api]);

    const months = [
        "Diciembre", "Enero", "Febrero", "Marzo", "Abril",
        "Mayo", "Junio", "Julio", "Agosto", "Setiembre",
        "Octubre", "Noviembre"
    ];

    const getDesglose = () => {
        let desglose = [];

        if (bonus && Array.isArray(bonus.Desgloce)) {
            desglose = months.map((month, index) => {
                const item = bonus.Desgloce[index];
                const monto = parseFloat(item);
                const year = index === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear();

                return {
                    name: `${month} ${year}`,
                    monto: !isNaN(monto) ? formatter.format(monto) : "Monto inválido"
                };
            });
        }

        return desglose;
    };

    const handleNavigateBack = () => {
        if (employee) {
            navigateTo(<Employee cedula={employee.Cedula} />);
        } else {
            navigateBack();
        }
    };

    const handleReenviarEmail = async () => {
        if (!bonus || !bonus.cedula) {
            console.error("No hay información de aguinaldo o cédula del trabajador.");
            return;
        }
    
        setOpenBackdrop(true);
    
        try {
            // reenvío del correo, sin alterar el estado de pago
            const response = await api.ResendPayChristmasBonus(bonus.cedula, bonus.Periodo);
            
            if (response && response.success) {
                console.log("Correo reenviado con éxito.");
            } else {
                console.warn("El correo no se pudo reenviar. Respuesta de la API:", response);
            }
        } catch (error) {
            console.error("Error reenviando el correo de aguinaldo:", error);
        } finally {
            setOpenBackdrop(false);
        }
    };
    

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    component="a"
                    href="#"
                    onClick={handleNavigateBack}
                    label="Empleado"
                    icon={<PeopleIcon fontSize="small" />}
                />
                <StyledBreadcrumb label="Aguinaldo" />
            </Breadcrumbs>
            <Paper elevation={3} sx={{ textAlign: 'left', padding: 1, margin: 2 }}>
                <Typography variant="h4" color="primary">
                    {"Aguinaldo"}
                </Typography>
                <br />
                {bonus == null ? (
                    <Stack direction="column" spacing={2}>
                        <Skeleton variant="rectangular" width={210} height={60} />
                        <Skeleton variant="rectangular" width={150} height={30} />
                    </Stack>
                ) : (
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <Paper elevation={1} sx={{ margin: 1, padding: 1 }}>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h6" color="primary">
                                        EMPRESA
                                    </Typography>
                                    <Typography variant="body">
                                        {company ? company.Nombre : "..."}
                                    </Typography>
                                    <Typography variant="body">
                                        <Typography variant="body" fontWeight="bold">
                                            Ced:
                                        </Typography>{" "}
                                        {company ? company.CedulaJuridica : "..."}
                                    </Typography>
                                    {company ? (
                                        <Typography variant="body">
                                            <Typography variant="body" fontWeight="bold">Domicilio: </Typography>
                                            {company.Direccion}
                                        </Typography>
                                    ) : (
                                        <Skeleton variant="rectangular" width={150} height={30} />
                                    )}
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper elevation={1} sx={{ margin: 1, padding: 1 }}>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h6" color="primary">
                                        TRABAJADOR/A
                                    </Typography>
                                    <Typography variant="body"> {" "}
                                        {employee ? employee.Nombre + " " + employee.Apellidos : Bonus.nombre}
                                    </Typography>
                                    <Typography variant="body">
                                        <Typography variant="body" fontWeight="bold">
                                            Cédula:
                                        </Typography>{" "}
                                        {employee ? employee.Cedula : Bonus.cedula}
                                    </Typography>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <Grid container rowSpacing={1} margin={1} padding={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="body1" fontWeight="bold">
                                            Aguinaldo del año:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">
                                            {new Date().getFullYear()}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="primary" onClick={handleReenviarEmail} endIcon={<EmailIcon />}>
                                            Reenviar correo
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="desglose de aguinaldo">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>MES</StyledTableCell>
                                            <StyledTableCell align="right">Desgloce por mes</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {getDesglose().map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{row.monto}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                        <StyledTableRow key={"Total"}>
                                            <StyledTableCell component="th" scope="row">
                                                <Typography variant="body" fontWeight="bold">
                                                    TOTAL DE AGUINALDO
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {bonus ? formatter.format(bonus.Total) : " - "}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                )}
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default PayBonusPage;
