// Login.js
import React, { useState } from 'react';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { TextField, Button, Paper, Typography, Container, Link } from '@mui/material';

import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const BackButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
}));

const Login = ({ onLogin, onNeedRegister }) => {
  const [isRegisterView, setIsRegisterView] = useState(false);
  const [username, setUsername] = useState(undefined);
  const [password, setPassword] = useState(undefined);

  const handleLogin = () => {
    const userPool = new CognitoUserPool({
      UserPoolId: 'us-east-2_5bV3wZbbm',
      ClientId: '1p663tk0cf38mde6gtjf4t2ovn',
    });

    const userData = { Username: username, Pool: userPool };
    const authenticationData = { Username: username, Password: password };

    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        console.log('Sesión iniciada con éxito:', session);
        onLogin(cognitoUser, session);
      },
      onFailure: (err) => {
        console.error('Error al iniciar sesión:', err);
      },
    });
  };

  return (
    <StyledContainer component="main" maxWidth="xs">
      <StyledPaper elevation={3}>
        <Typography variant="h5">Iniciar sesión</Typography>
        <StyledForm>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Nombre de usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Contraseña"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <StyledSubmitButton
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogin}
          >
            Iniciar sesión
          </StyledSubmitButton>
          {/* Enlace para cambiar a la pantalla de registro */}
          <Link onClick={onNeedRegister} style={{ cursor: 'pointer', marginTop: '10px' }}>
            Nuevo Usuario?
          </Link>
          
        </StyledForm>
      </StyledPaper>
    </StyledContainer>
  );
};

export default Login;
