import React, { useState, useEffect } from 'react';
import { useApiManager } from 'lib/ApiContext';
import { Grid, Breadcrumbs, Typography, Avatar, Divider, Button, TextField, Chip, emphasize, Backdrop, CircularProgress } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import { styled, useTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkSchedule from './WorkSchedule';
import { CPTextField, StyledBreadcrumb } from 'components/CPStyles';
import WorkSchedules from './WorkSchedules';
import { useNavigation } from 'lib/NavigationContext';

const EditWorkSchedule = ({ schedule }) => {

    const api = useApiManager();
    const theme = useTheme();
    const { navigateTo } = useNavigation();
  
    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    const [workSchedule, setWorkSchedule] = useState(schedule);

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setWorkSchedule({ ...workSchedule, [name]: value });
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name != 'HoraDeSalida' || name != 'HoraDeInicio') {
            if (value.trim() === '') {
                setErrors({ ...errors, [name]: 'Este campo es requerido' });
            } else if (workSchedule[name] == 0) {
                setErrors({ ...errors, [name]: 'Este campo es requerido' });
            }
            else {
                setErrors({ ...errors, [name]: undefined });
            }
        }
    };

    const validateFields = () => {
        let newErrors = {};
        for (const key in workSchedule) {
            if (key != 'HoraDeSalida' && key != 'HoraDeInicio') {
                if (typeof workSchedule[key] === 'string' && workSchedule[key].trim() === '') {
                    newErrors[key] = 'Este campo es requerido';
                }
            }
            else if (workSchedule[key] == 0) {
                newErrors[key] = 'Este campo es requerido';
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        // Call your API to add the new workSchedule
        // Example: await api.addWorkSchedule(workSchedule);
        await setOpenBackdrop(true);

        if (!validateFields()) {
            setOpenBackdrop(false)
            
        }
        else {
            api.CreateWorkSchedule(workSchedule).catch((e) => {
                setOpenBackdrop(false); console.error(e);
            })
                .then(navigateTo(<WorkSchedule codigo={workSchedule.Codigo} />));
        }
    };

    const handleNavigateBack = (target) =>
    {
        if (target == "puesto")
        {
            navigateTo(<WorkSchedule codigo={workSchedule.Codigo} />);
        }
        else
        {
            navigateTo(<WorkSchedules />);
        }
    }

    return <form>
        <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
                component="a"
                href="#"
                onClick={()=> handleNavigateBack("dashboard")}
                label="Principal"
                icon={<DashboardIcon fontSize="small" />}
            />
            <StyledBreadcrumb component="a" href="#" label="Puesto" onClick={()=> handleNavigateBack("puesto")} />
            <StyledBreadcrumb
                label="Editar Puesto" 
            />
        </Breadcrumbs>
        <Paper elevation={3} sx={{ textAlign: 'left', padding: 1, margin: 2 }}>
            <Typography variant="h4" color="primary" gutterBottom>
                {"Editar Puesto"}
            </Typography>
            <br />
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                    <CPTextField
                        required
                        id="Codigo"
                        name="Codigo"
                        label="Codigo"
                        value={workSchedule.Codigo}
                        disabled 
                        helperText={errors["Codigo"]}
                        variant="filled"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CPTextField
                        required
                        id="DiasLaborales"
                        name="DiasLaborales"
                        label="DiasLaborales"
                        value={workSchedule.DiasLaborales}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors["DiasLaborales"]}
                        helperText={errors["DiasLaborales"]}
                        variant="filled"
                        color='secondary'
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CPTextField
                        required
                        id="HoraDeInicio"
                        name="HoraDeInicio"
                        label="HoraDeInicio"
                        value={workSchedule.HoraDeInicio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors["HoraDeInicio"]}
                        helperText={errors["HoraDeInicio"]}
                        variant="filled"
                        fullWidth
                        type='number'
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CPTextField
                        required
                        id="HoraDeSalida"
                        name="HoraDeSalida"
                        label="Hora de salida"
                        value={workSchedule.HoraDeSalida}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        type='number'
                    />
                </Grid>
                <Grid item xs={4}></Grid>
            </Grid>
            <br />
            <Divider orientation='horizontal' />
            <Button onClick={handleSubmit} variant="contained" sx={{ margin: 2 }}>Salvar</Button>
        </Paper>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </form>
}

export default EditWorkSchedule;