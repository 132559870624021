import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { List, ListItemAvatar, Avatar, ListItemText, ListItemButton } from '@mui/material';
import { deepPurple, deepOrange } from '@mui/material/colors';

const ExceptionsList = ({ exceptions, onSelected }) => {
  // Basic validation to check if exceptions is an array
  if (!Array.isArray(exceptions)) {
    return <div>Error: exceptions is not an array</div>;
  }

  return (
    <List>
      {exceptions.map((exception) => {
        // Check for required exception properties
        if (!exception.IDException || !exception.Cedula || !exception.Fecha || !exception.Horas || !exception.Razon
            ) {
          return null; // or handle the error as needed
        }

        return (
          <ListItemButton 
            id={exception.IDException} 
            key={exception.IDException} 
            onClick={() => onSelected(exception.IDException)}
            aria-label={`Select ${exception.Cedula}`}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor:deepPurple[700] }} />
            </ListItemAvatar>
            <ListItemText 
              primary={exception.Razon} 
              secondary={`Cedula:${exception.Cedula} Fecha:${exception.Fecha} Cantidad de horas:${exception.Horas
              }h`} 
            />
          </ListItemButton>
        );
      })}
    </List>
  );
};

// PropTypes for type checking
ExceptionsList.propTypes = {
  exceptions: PropTypes.arrayOf(PropTypes.shape({
    IDException: PropTypes.string.isRequired,
    Cedula: PropTypes.string.isRequired,
    Fecha: PropTypes.number.isRequired,
    Horas: PropTypes.number.isRequired,
    Razon: PropTypes.string.isRequired
  })).isRequired,
  onSelected: PropTypes.func.isRequired
};

export default ExceptionsList;
