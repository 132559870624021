import React, { useState, useEffect, useRef } from 'react';
import { useApiManager } from 'lib/ApiContext';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AlertDialog from 'components/AlertDialog';
import PeopleIcon from '@mui/icons-material/People';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BadgeIcon from '@mui/icons-material/Badge';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import SickIcon from '@mui/icons-material/Sick';
import SavingsIcon from '@mui/icons-material/Savings';
import WorkIcon from '@mui/icons-material/Work';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyIcon from '@mui/icons-material/Key';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

// Import the EmployeeList component
import Employees from './employeesPages/Employees';

import WorkSchedules from './workSchedulesPages/WorkSchedules';
import Exceptions from './exceptionsPages/Exceptions';
import { useNavigation, NavigationProvider } from 'lib/NavigationContext';
import Incapacities from './incapcitiesPages/Incapacities';
import logoPath from '../images/LogoHiRez.png'
import pslogo from '../images/PSlogoCircle.png'
import { Link } from '@mui/material';

// Import Dashboard
import Dashboard from './dashboardPages/Dashboard';
import ControlPanel from './ControlPanel';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});




const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const menuItems = [
    { index: 0, text: 'Vista General', icon: <DashboardIcon /> },
    { index: 1, text: 'Empleados', icon: <PeopleIcon /> },
    //{ index: 2, text: 'Excepciones', icon: <PunchClockIcon /> },
    //{ index: 3, text: 'Incapacidades', icon: <SickIcon /> },
    //{ index: 4, text: 'Deducciones', icon: <SavingsIcon /> },
    { index: 5, text: 'Puestos', icon: <WorkIcon /> },

];

const menuItems2 = [
    { index: 0, text: 'Ajustes Periodicos', icon: <SettingsIcon /> },
    { index: 1, text: 'Seguridad', icon: <KeyIcon /> }
];

const Footer = () =>
{
    return <Box  component="footer"  sx={{
        display: 'flex',
        alignItems: 'center',  // Aligns items vertically center
        justifyContent: 'center', // Centers items horizontally in the container
        bgcolor: 'background.paper',
        py: 2,
        position: 'fixed', // Fix position to the bottom of the screen
        bottom: 0, // Position it at the bottom
        width: '100%', // Ensure it takes the full width
    }}>
       <img  src={pslogo} alt="Phothon Shaper Logo" style={{ height: '50px', marginRight: '5px', marginLeft: '0' }} />
        <Typography variant="body1" align="center" color="text.secondary">
                    Phothon Shapers © {new Date().getFullYear()}
        </Typography>
       
    </Box>
}



const Home = () => {
    const api = useApiManager();
    const [userData, setUserData] = useState(null);
    const [company, setCompany] = useState(null);
    const {currentView, navigateTo, alertToDisplay, showAlert}  = useNavigation();
  
    const onNavigateTo = (TargetView) => {
        navigateTo(TargetView);
    }
    const onShowError = (title, errorMessage, okCallback, showOkCancel) => {
        showAlert(title, errorMessage, okCallback, showOkCancel);
    }
    //const [activeView, setActiveView] = useState(<Employees navigateTo={onNavigateTo} />);
    useEffect(()=>{
        api.onError = onShowError;
        navigateTo(< ControlPanel />);
    },[]);
    
    /*useEffect(()=>{
        if (alertDlg && alertToDisplay)
        {
            alertDlg:Open(alertToDisplay.Message, alertToDisplay.OKCallback);
        }
    }, [alertToDisplay])*/
  
    const onSelectNavButton = (index) => {
        switch (index) {
            case 0:
                navigateTo(< ControlPanel />);
                break;
            case 5:
                navigateTo(<WorkSchedules navigateTo={onNavigateTo} />);
                break;
            case 2:
                navigateTo(<Exceptions navigateTo={onNavigateTo} />);
                break;
            case 3:
                navigateTo(<Incapacities navigateTo={onNavigateTo} />);
                break;  
            default:
                navigateTo(<Employees />);
                break;
        }
    }

    const onSecondarySelectNavButton = (index) => {
        switch (index) {
            case 0:
                //setActiveView(<Employees onSelectedEmployee={onSelectedEmployee}/>);
                break;
            default:
                //setActiveView(<Employees onSelectedEmployee={onSelectedEmployee}/>);
                break;
        }
    }

   

   

    useEffect(() => {
        api.GetCurrentUserCompany().then(r => { setUserData(r); api.GetCompany(r.companyID).then( r2 => setCompany(r2)) });
    }, []);

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return <div style={{ paddingBottom: '100px' }}>
        {userData ? (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open} sx={{backgroundColor: '#283e56'}}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        
                        <Box component="div" sx={{textAlign:"left", display: 'flex',  flexGrow: 1}}>
                            <img  src={company?.CompanyLogo} alt="Logo" style={{ height: '50px', marginRight: '10px', marginLeft: '0' }} />
                            <Typography variant="h4" noWrap component="div">
                            {company?.Nombre}
                            </Typography>
                        </Box>
                        <img  src={logoPath} alt="LogoCP" style={{ height: '50px', marginRight: '10px'}} />
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        {menuItems.map((menuItem) => (
                            <ListItem key={menuItem.text} disablePadding onClick={(event) => onSelectNavButton(menuItem.index)}>
                                <ListItemButton sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}>
                                    <ListItemIcon sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}>
                                        {menuItem.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menuItem.text} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {menuItems2.map((menuItem) => (
                            <ListItem key={menuItem.text} disablePadding onClick={(event) => onSecondarySelectNavButton(menuItem.index)}>
                                <ListItemButton sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}>
                                    <ListItemIcon sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}>
                                        {menuItem.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menuItem.text} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {/*<Title>Empleados</Title>
           Render the EmployeeList component here and pass the employee data 
          <EmployeeList employees={employees} />*/}
                    {currentView}
                    <AlertDialog id="mainAlert" dialogContent={alertToDisplay} />
                </Box>
                </Box>) 
                : 
                (<Stack spacing={1}>
                    {/* For variant="text", adjust the height via font-size */}
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    {/* For other variants, adjust the size with `width` and `height` */}
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rounded" width={210} height={60} />
                </Stack>)
        }
        <Footer/>
    </div>
};

export default Home;