import React, { useState } from 'react';
import { CognitoUserPool, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Link, TextField, Button, Paper, Typography, Container, IconButton, InputAdornment, Alert } from '@mui/material';
import { styled } from '@mui/system';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Collapse from '@mui/material/Collapse';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%', // Asegura que el botón esté bien posicionado en la esquina superior izquierda
}));

const BackButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    textAlign: 'center', 
}));

const StyledForm = styled('form')(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(1),
}));

const StyledSubmitButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const Registration = ({onLogin, onLogout}) => {
    const userPool = new CognitoUserPool({
        UserPoolId: 'us-east-2_5bV3wZbbm',
        ClientId: '1p663tk0cf38mde6gtjf4t2ovn',
    });
    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [password2, setPassword2] = useState('');
    const [showPassword2, setShowPassword2] = useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [showRegisterSuccess, setShowRegisterSuccess] = React.useState(false);
    const [showVerificationSuccess, setShowVerificationSuccess] = React.useState(false);
    const [verificationCode, setVerificationCode] = React.useState('');
    const [message, setMessage] = React.useState("");
    const [errors, setErrors] = useState({});
    
    const doBacktologin = () => {
      onLogout(); 
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validateFields = () => {
        let newErrors = {};
        if (givenName.trim() === '') newErrors["givenName"] = 'Este campo es requerido';
        if (familyName.trim() === '') newErrors["familyName"] = 'Este campo es requerido';
        if (password.trim() === '') newErrors["password"] = 'Este campo es requerido';
        if (password2.trim() === '') newErrors["password2"] = 'Este campo es requerido';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleRegistration = async () => {
        const companies = await axios({
            method: 'GET',
            url: `https://api.controlplanilla.com/m/utils/getAllowCompanies?em=${email.toLowerCase().trim()}`,
        });
        if (companies && companies.data.length === 0) {
            setOpenAlert(true);
            return;
        }
        if (password !== password2) {
            setErrors({...errors, "password2": "Clave y Confirmacion son diferentes."});
            return;
        }
        setOpenAlert(false);

        const attributeList = [
            new CognitoUserAttribute({ Name: 'given_name', Value: givenName.trim() }),
            new CognitoUserAttribute({ Name: 'family_name', Value: familyName.trim() }),
            new CognitoUserAttribute({ Name: 'email', Value: email.toLowerCase().trim() })
        ];

        userPool.signUp(email, password.trim(), attributeList, null, (err, result) => {
            if (err) {
                if (err.code === "UsernameExistsException") {
                    setShowRegisterSuccess(true);
                    return;
                } else {
                    console.log(err);
                    setMessage(err.message);
                    setOpenAlert(true);
                    return;
                }
            }
            setShowRegisterSuccess(true);
        });
    };

    const handleResentVerificationCode = async () => {
        const userData = { Username: email, Pool: userPool };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                console.error(err);
                return;
            }
            console.log('New verification code sent!', result);
        });
    };

    const handleVerification = async () => {
        const userData = { Username: email, Pool: userPool };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
            if (err) {
                if (err.code === 'ExpiredCodeException') {
                    handleResentVerificationCode();
                    setMessage('El código suministrado ha expirado. Te hemos enviado un nuevo código, por favor revisa tu email.');
                    return;
                } else {
                    console.error(err);
                    setMessage(err.message);
                    return;
                }
            }
            console.log('Registration confirmed!', result);
            setShowVerificationSuccess(true);
        });
    };

    return (
        <StyledContainer component="main" maxWidth="xs">
            {!showRegisterSuccess ? 
            <StyledPaper elevation={3}>
                <BackButton onClick={doBacktologin}>
                    <ArrowBackIcon />
                </BackButton>
                <TitleTypography variant="h5">Register</TitleTypography>
                <Collapse in={openAlert}>
                    <Alert variant="filled" severity="error">
                        Su email no está preautorizado para acceder a Control Planilla. Contacte su administrador o escríbanos a admin@controlplanilla.com.
                    </Alert>
                </Collapse>
                <StyledForm>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Nombre"
                        value={givenName}
                        onBlur={() => validateFields()}
                        error={!!errors["givenName"]}
                        helperText={errors["givenName"]}
                        required
                        onChange={(e) => { setGivenName(e.target.value.trim()); validateFields(); }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        label="Apellidos"
                        value={familyName}
                        onBlur={() => validateFields()}
                        error={!!errors["familyName"]}
                        helperText={errors["familyName"]}
                        onChange={(e) => { setFamilyName(e.target.value.trim()); validateFields(); }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        label="Email"
                        value={email}
                        onBlur={() => validateFields()}
                        error={!!errors["email"]}
                        helperText={errors["email"]}
                        onChange={(e) => { setEmail(e.target.value.trim()); validateFields(); }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        label="Clave"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onBlur={() => validateFields()}
                        error={!!errors["password"]}
                        helperText={errors["password"]}
                        onChange={(e) => { setPassword(e.target.value); validateFields(); }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        label="Confirmar Clave"
                        type={showPassword2 ? 'text' : 'password'}
                        value={password2}
                        onBlur={() => validateFields()}
                        error={!!errors["password2"]}
                        helperText={errors["password2"]}
                        onChange={(e) => { setPassword2(e.target.value); validateFields(); }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <StyledSubmitButton
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleRegistration}
                    >
                        Registrarse
                    </StyledSubmitButton>
                </StyledForm>
            </StyledPaper>
            :
            <StyledPaper elevation={3}>
                <TitleTypography variant="h5">Verifica tu Email</TitleTypography>
                <Typography>Por favor, introduce el código de verificación que te enviamos por email.</Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Código de Verificación"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                />
                <StyledSubmitButton
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleVerification}
                >
                    Verificar
                </StyledSubmitButton>
            </StyledPaper>
            }
        </StyledContainer>
    );
};

export default Registration;
