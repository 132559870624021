import React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { DataGrid, GridRowsProp, GridColDef, GridActionsCell, GridActionsCellItem} from '@mui/x-data-grid';
import { List, ListItemAvatar, Avatar, ListItemText, ListItemButton } from '@mui/material';
import { deepPurple, deepOrange } from '@mui/material/colors';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigation} from 'lib/NavigationContext'
import PayRollPage from 'pages/Reports/PayRollPage';
import { getValue } from '@mui/system';

const dateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric"
  };
const PaymentsGrid = ({ employee, payments, onSelected, onDeleted }) => {

   const formatter = new Intl.NumberFormat('es-CR', { style: 'currency', currency: 'CRC' });

  // Basic validation to check if payments is an array
  const { navigateTo } = useNavigation();
  const deleteAction = (id) => {
    if (onDeleted)
        onDeleted(id);
  }
  const selectAction = (id) => {
    if (onSelected)
        onSelected(id);
  }

  const columns = React.useMemo(() => {
    return [
      { field: 'FechaInicio', headerName: 'Desde', width: 150,
        valueGetter: (params) => {
        if (!params.value) {
            return params.value;
        }
        // Convert the decimal value to a percentage
        return new Date(params.value+"T00:00:00").toLocaleDateString("es-CR", dateOptions);
        }, 
      },
      { field: 'FechaFinal', headerName: 'Hasta', width: 150,
        valueGetter: (params) => {
        if (!params.value) {
            return params.value;
        }
        // Convert the decimal value to a percentage
        return new Date(params.value+"T00:00:00").toLocaleDateString("es-CR", dateOptions);
        }, 
    },

      
      { field: 'Bruto', headerName: 'Bruto', width: 150,
        valueGetter: (params) => {
            if (!params.value) {
                return params.value;
            }
            // Convert the decimal value to a percentage
            return formatter.format(params.value);
        }, 
      },
      { field: 'Neto', headerName: 'Neto', width: 150,
      valueGetter: (params) => {
          if (!params.value) {
              return params.value;
          }
          // Convert the decimal value to a percentage
          return formatter.format(params.value);
        },
      }, // falta el estado.
      {
        field: 'actions',
        width: 30,
        type: 'actions',
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              icon={<CreateIcon/>}
              label="Ver"
              onClick={() => {
                console.log("Pago: ", row);
                let payData = {...row, Cedula: employee.Cedula};
                navigateTo(<PayRollPage employee={employee} fromDate={row.FechaInicio} toDate={row.FechaFinal} Payment={payData} />)
              }}
            />,
          ];
        },
      },
      {
        field: 'action',
        width: 30,
        type: 'actions',
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon/>}
              label="DELETE"
              onClick={() => {
                deleteAction (id)
              }}
            />,
          ];
        },
    },
    ];
  },[]);


  if (!Array.isArray(payments)) {
    return <div>Error: payments is not an array</div>;
  }
    return (
    <DataGrid rows={payments} columns={columns} 
    getRowId={(row)=>{ return row?row.IDPayment:0;}} sx={{height:250}}
    initialState={{
      sorting: {
        sortModel: [{ field: 'FechaInicio', sort: 'desc' }],
      },
    }}
    />
  );
};

// PropTypes for type checking
PaymentsGrid.propTypes = {
  exceptions: PropTypes.arrayOf(PropTypes.shape({
    IDPayment: PropTypes.string.isRequired,
    FechaInicio: PropTypes.string.isRequired,
    FechaFinal: PropTypes.string.isRequired,
    Bruto: PropTypes.number.isRequired,
    Neto: PropTypes.number.isRequired,
    EstadoDelPago: PropTypes.number.isRequired,
  })).isRequired,
  onSelected: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired
};

export default PaymentsGrid;
