import React, { useState, useEffect } from 'react';
import { useApiManager } from 'lib/ApiContext';
import { Grid, Box, Stack, Breadcrumbs, Typography, Avatar, Divider, Button, MenuItem, Backdrop, CircularProgress, Switch } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { CPTextField, StyledBreadcrumb, CPSelect } from 'components/CPStyles';
import Employee from 'pages/employeesPages/Employee';
import { useNavigation } from '../../lib/NavigationContext'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { grey, deepPurple } from '@mui/material/colors';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';

const initialModel = {
    IDVacation: '',
    FechaInicio: '',
    FechaFinal: '',
    Dias: 0,
    Pagados: false,
};

const NewVacation = ({ employee }) => {

    const api = useApiManager();
    const theme = useTheme();
    const { navigateTo } = useNavigation();
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [vacations, setVacations] = useState(initialModel);

    const [errors, setErrors] = useState({});
    useEffect(() => {
        if (employee) {
            setVacations({ ...vacations, "Cedula": employee.Cedula /*"IDException": uuid()*/ });
        }
    }, []);


    const validateFields = () => {
        let newErrors = {};
        
        if (!vacations.FechaInicio) {
            newErrors["FechaInicio"] = "Fecha de inicio es requerida";
        } else if (vacations.FechaFinal && dayjs(vacations.FechaInicio).isAfter(vacations.FechaFinal)) {
            newErrors["FechaInicio"] = "Fecha de inicio no puede ser después de la fecha final";
        }
    
        if (!vacations.FechaFinal) {
            newErrors["FechaFinal"] = "Fecha final es requerida";
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    
    const handleSubmit = async () => {
        if (!validateFields()) return; // Detén el flujo si hay errores
        setOpenBackdrop(true);

        try {
            await api.CreateVacation(vacations);
            navigateTo(<Employee cedula={vacations.Cedula} />);
        } catch (error) {
            console.error("Error actualizando vacaciones:", error);
            alert("Hubo un problema al agregar las vacaciones. Por favor, inténtalo de nuevo.");
        } finally {
            setOpenBackdrop(false);
        }
        
    };

    const handleNavigateBack = (target) => {
        navigateTo(<Employee cedula={vacations.Cedula} />);
    }

    return <form>
        <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
                component="a"
                href="#"
                onClick={() => handleNavigateBack("dashboard")}
                label="Principal"
                icon={<DashboardIcon fontSize="small" />}
            />
            <StyledBreadcrumb component="a" href="#" label="Ajustes" onClick={() => handleNavigateBack("Vacaciones")} />
            <StyledBreadcrumb
                label="Nuevas Vacaciones"
            />
        </Breadcrumbs>
        <Paper elevation={3} sx={{ textAlign: 'left', padding: 1, margin: 2 }}>
            <Typography variant="h4" color="primary" gutterBottom>
                {"Nuevas Vacaciones"}
            </Typography>
            <br />
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={6}>
                    <Stack direction="row" spacing={1} >
                        <Avatar sx={{ bgcolor: deepPurple[500], width: 40, height: 40 }}>
                            {employee.Nombre.substring(0, 1)}
                        </Avatar>
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography variant="h5" >
                                {employee.Nombre + " " + employee.Apellidos}
                            </Typography>
                            <Typography variant="subscript1" style={{ color: grey[500] }}>
                                {employee.Cargo}
                            </Typography>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography variant="body1" >
                    {'Pagados'}
                </Typography>
                <Switch 
                            checked={vacations.Pagados} // controlled component
                            onChange={(e) => {
                                setVacations({ ...vacations, Pagados: e.target.checked });
                            }} 
                            inputProps={{ 'aria-label': 'controlled' }} 
                            color="secondary"
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Fecha Inicio"
                            fullWidth
                            required
                            value={dayjs(vacations.FechaInicio)}
                            onChange={(newValue) => {
                                setVacations({ ...vacations, FechaInicio: newValue.format("YYYY-MM-DD") });
                            }}
                            renderInput={(params) => (
                                <CPTextField
                                    {...params}
                                    required
                                    fullWidth
                                    error={!!errors["FechaInicio"]}
                                    helperText={errors["FechaInicio"]}
                                    variant="filled"
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Fecha Final"
                            fullWidth
                            required
                            value={dayjs(vacations.FechaFinal)}
                            onChange={(newValue) => {
                                setVacations({ ...vacations, FechaFinal: newValue.format("YYYY-MM-DD") });
                            }}
                            renderInput={(params) => (
                                <CPTextField
                                    {...params}
                                    required
                                    fullWidth
                                    error={!!errors["FechaFinal"]}
                                    helperText={errors["FechaFinal"]}
                                    variant="filled"
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <br />
            <Divider orientation='horizontal' />
            <Button onClick={handleSubmit} variant="contained" sx={{ margin: 2 }}>Crear</Button>
        </Paper>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </form>
}

export default NewVacation;