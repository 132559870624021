import React, { useState, useEffect } from 'react';
import { useApiManager } from 'lib/ApiContext';
import { Grid, Stack, Select, MenuItem, Breadcrumbs, Link, Typography, Avatar, Divider, Button, TextField, Chip, emphasize, Backdrop, CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useNavigation } from 'lib/NavigationContext';
import { CPTextField, CPSelect, StyledBreadcrumb } from 'components/CPStyles';
import CompanyPayrollGrid from 'components/CompanyPayrollGrid';

import { green } from '@mui/material/colors';

const Dashboard = () => {
    const api = useApiManager();
    const theme = useTheme();
    const { navigateTo, navigateBack } = useNavigation();
    const [periodoPago, setPeridoPago] = useState({ fechaInicio: '2024/01/01', fechaFinal: '2024/01/01' });
    const [payrolls, setPayrolls] = useState([]);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        const startDate = yyyy + "-" + mm + '-01';
        const endDate = yyyy + "-" + mm + '-' + dd;
        setPeridoPago({ "fechaInicio": startDate, "fechaFinal": endDate });

    }, []);

    const handleCalularPagos = async () => {
        setOpenBackdrop(true);
        setPayrolls([]);
        setPayrolls(await api.CalculateCompanyPayroll(periodoPago.fechaInicio, periodoPago.fechaFinal));
        setOpenBackdrop(false);
    }

    const handlePagarSelecionados = () => {
        if (selectedRows.length > 0) {
            setOpenBackdrop(true);
            const payrollItems = payrolls.Items;
            const selectedPayments = payrollItems.filter(pay => selectedRows.includes(pay.IDPayment));
            const paymentPromises = selectedPayments.map(payment => api.DoPayment(payment.Cedula, periodoPago.fechaInicio, periodoPago.fechaFinal).then(() => payment.EstadoDelPago = 1));
            Promise.all(paymentPromises).then((updatedPayments) => {
                const updatedPayrollItems = payrollItems.map(item => {
                    const updatedItem = updatedPayments.find(upd => upd.IDPayment === item.IDPayment);
                    return updatedItem || item; // Use the updated item or the original one
                });

                setPayrolls({ ...payrolls, Items: updatedPayrollItems }); // Assuming payrolls is an object with an Items array
                setSelectedRows([]);
                setOpenBackdrop(false);
            }).catch(error => {
                console.error("Error updating payments:", error);
                setOpenBackdrop(false);
            });;

        }
    }

    return <div>
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="#ControlPanel" onClick={()=>{
             navigateBack()   
            }}>
                ControlPanel
            </Link>
            
            <Link
                underline="hover"
                color="inherit"
            >
              Pago de Salarios
            </Link>
        </Breadcrumbs>


        <Paper elevation={3} sx={{ textAlign: 'left', padding: 1, margin: 2 }}>
            <Typography variant="h4" color="primary" gutterBottom>
                {"Pago de Salarios"}
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Typography variant="h6" margin={1} gutterBottom alignContent={'center'}>Planilla</Typography>

                    <Stack direction="row" spacing={1} margin={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Desde:"
                                fullWidth
                                required
                                value={dayjs(periodoPago.fechaInicio)}
                                onChange={(newValue) => {
                                    setPeridoPago({ ...periodoPago, fechaInicio: newValue.format("YYYY-MM-DD") });
                                }}
                                renderInput={(params) => (
                                    <CPTextField
                                        {...params}
                                        required
                                        fullWidth
                                        variant="outline"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Hasta:"
                                fullWidth
                                required
                                value={dayjs(periodoPago.fechaFinal)}
                                onChange={(newValue) => {
                                    setPeridoPago({ ...periodoPago, fechaFinal: newValue.format("YYYY-MM-DD") });
                                }}
                                renderInput={(params) => (
                                    <CPTextField
                                        {...params}
                                        required
                                        fullWidth
                                        variant="outline"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <Button variant='contained' sx={{ margin: 2 }} onClick={handleCalularPagos}>Calcular Pagos</Button> 
                        <Button variant='contained' disabled={selectedRows.length <= 0} sx={{ margin: 2, bgcolor: green[700] }} onClick={handlePagarSelecionados}>Pagar</Button>
                    </Stack>
                </Grid>
                <Grid item xs={12} >
                    {
                        payrolls ?
                            <CompanyPayrollGrid payrolls={payrolls.Items} onSelected={(sel) => { setSelectedRows(sel); }} />
                            :
                            <Typography></Typography>
                    }
                </Grid>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Paper>
    </div>

};

export default Dashboard;