import React, { useState, useEffect } from 'react';
import { useApiManager } from 'lib/ApiContext';
import { Grid, Breadcrumbs, Typography, Divider, Button, Backdrop, CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Employee from './Employee';
import { CPTextField, CPSelect, StyledBreadcrumb } from 'components/CPStyles';
import Employees from './Employees';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useNavigation } from '../../lib/NavigationContext';

const initialModel = {
    Horario: '',
    Nombre: '',
    Apellidos: '',
    Cargo: '',
    Activo: true,
    FechaDeSalida: '',
    Jornada: 8,
    Email: '',
    Cedula: '',
    FechaDeInicio: new Date().toJSON(),
    Salario: 0,
    Banco: '',
    Cuenta: '',
};

const EditEmployee = ({ oldEmployee }) => {
    const api = useApiManager();
    const { navigateTo } = useNavigation();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [employee, setEmployee] = useState(oldEmployee);
    const [workingSchedules, setWorkingSchedules] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        api.GetWorkSchedules().then((r) => setWorkingSchedules(r));
    }, []);

    const validateFields = () => {
        let newErrors = {};
        for (const key in employee) {
            if (key !== 'FechaDeSalida' && key !== 'Jornada' && key !== 'Horario') {
                if (typeof employee[key] === 'string' && employee[key].trim() === '') {
                    newErrors[key] = 'Este campo es requerido';
                }
            }
        }
        if (employee.Salario <= 0) {
            newErrors['Salario'] = 'Salario debe ser mayor que 0';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployee({ ...employee, [name]: value });
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (value.trim() === '') {
            setErrors({ ...errors, [name]: 'Este campo es requerido' });
        } else {
            setErrors({ ...errors, [name]: undefined });
        }
    };

    const handleSubmit = async () => {
        setOpenBackdrop(true);

        if (!validateFields()) {
            setOpenBackdrop(false);
        } else {
            api.CreateEmployee(employee)
                .catch((e) => {
                    setOpenBackdrop(false);
                    console.error(e);
                })
                .then(() => navigateTo(<Employee cedula={employee.Cedula} />));
        }
    };

    const handleNavigateBack = () => {
        navigateTo(<Employees />);
    };

    return (
        <form>
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    component="a"
                    href="#"
                    onClick={handleNavigateBack}
                    label="Principal"
                    icon={<DashboardIcon fontSize="small" />}
                />
                <StyledBreadcrumb component="a" href="#" label="Empleados" onClick={handleNavigateBack} />
                <StyledBreadcrumb label="Editar Empleado" />
            </Breadcrumbs>
            <Paper elevation={3} sx={{ textAlign: 'left', padding: 1, margin: 2 }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    {"Editar Empleado"}
                </Typography>
                <br />
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {/* Cedula */}
                    <Grid item xs={4}>
                        <CPTextField
                            required
                            id="Cedula"
                            name="Cedula"
                            label="Cédula"
                            value={employee.Cedula}
                            InputProps={{ readOnly: true }}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    {/* Nombre */}
                    <Grid item xs={4}>
                        <CPTextField
                            required
                            id="Nombre"
                            name="Nombre"
                            label="Nombre"
                            value={employee.Nombre}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors["Nombre"]}
                            helperText={errors["Nombre"]}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    {/* Apellidos */}
                    <Grid item xs={4}>
                        <CPTextField
                            required
                            id="Apellidos"
                            name="Apellidos"
                            label="Apellidos"
                            value={employee.Apellidos}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors["Apellidos"]}
                            helperText={errors["Apellidos"]}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    {/* Email */}
                    <Grid item xs={8}>
                        <CPTextField
                            required
                            id="Email"
                            name="Email"
                            label="Correo Electrónico"
                            value={employee.Email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors["Email"]}
                            helperText={errors["Email"]}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    {/* Banco */}
                    <Grid item xs={4}>
                        <CPTextField
                            required
                            id="Banco"
                            name="Banco"
                            label="Banco"
                            value={employee.Banco}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors["Banco"]}
                            helperText={errors["Banco"]}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    {/* Cuenta */}
                    <Grid item xs={4}>
                        <CPTextField
                            required
                            id="Cuenta"
                            name="Cuenta"
                            label="Número de Cuenta"
                            value={employee.Cuenta}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors["Cuenta"]}
                            helperText={errors["Cuenta"]}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    {/* Salario */}
                    <Grid item xs={4}>
                        <CPTextField
                            required
                            id="Salario"
                            name="Salario"
                            label="Salario (colones)"
                            value={employee.Salario}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors["Salario"]}
                            helperText={errors["Salario"]}
                            type="Number"
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <Button onClick={handleSubmit} variant="contained" sx={{ margin: 2 }}>
                    Guardar Cambios
                </Button>
            </Paper>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </form>
    );
};

export default EditEmployee;
