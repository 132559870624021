import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigation } from 'lib/NavigationContext';

// Dashboards:
import Dashboard from './dashboardPages/Dashboard';
import ChristmasBonus from './dashboardPages/ChristmasBonus'; 

// Iconos
import PaidIcon from '@mui/icons-material/Paid';
import SavingsIcon from '@mui/icons-material/Savings';

const ControlPanel = () => {
    const [payrolls, setPayrolls] = useState([]);
    const [showDashboard, setShowDashboard] = useState(false); // Estado para mostrar el Dashboard
    const [showBonus, setShowBonus] = useState(false); // Estado para mostrar el ChristmasBonus
    const { navigateTo } = useNavigation();

    const handleShowDashboard = () => {
        setShowDashboard(true); // Muestra el Dashboard
        navigateTo(<Dashboard />, true); // Navega a Dashboard
    };

    const handleBonus = () => {
        setShowBonus(true); // Muestra el Bonus
        navigateTo(<ChristmasBonus />, true ); // Navega a ChristmasBonus
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            gap={1}
            padding="0" // Elimina el padding del contenedor
        >
            {/* BOTON 1 */}
            <Button
                variant="contained"
                onClick={handleShowDashboard}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: "40vh",
                    width: "35vh",
                    borderRadius: "16px",
                    background: "#4567b7",
                    marginTop: "-19vh", // Ajusta el margen para que choque con el borde superior
                    '&:hover': {
                        backgroundColor: "#567ebf", // Cambia el color cuando el ratón esté encima
                    }
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <Typography variant="button" display="block" fontSize={30} sx={{
                        fontFamily: 'Arial ',
                    }} >
                        Calcular Planillas
                    </Typography>
                    <PaidIcon sx={{ fontSize: 100, marginTop: 1 }} />
                </Box>
            </Button>

            {/* BOTON 2 */}
            <Button
                variant="contained"
                onClick={handleBonus}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: "40vh",
                    width: "35vh",
                    borderRadius: "16px",
                    background: "#419240",
                    '&:hover': {
                        backgroundColor: "#49a448",
                    }
                }}
            >
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="button" display="block" fontSize={30}>
                        Calcular Aguinaldos
                    </Typography>
                    <SavingsIcon sx={{ fontSize: 100, marginTop: 1 }} />
                </Box>
            </Button>
        </Box>
    );
};

export default ControlPanel;
