import React, { useState, useEffect } from 'react';
import { useApiManager } from 'lib/ApiContext';
import { Grid, Stack, Breadcrumbs, Link, Typography, Paper, Button, Backdrop, CircularProgress } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useNavigation } from 'lib/NavigationContext';
import { CPTextField } from 'components/CPStyles';
import CompanyChristmasBonusGrid from 'components/CompanyChristmasBonusGrid'; 

const ChristmasBonus = () => {
    const api = useApiManager();
    const theme = useTheme();
    const { navigateTo, navigateBack } = useNavigation();
    const [periodoPago, setPeriodoPago] = useState('');  
    const [bonuses, setBonuses] = useState([]);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        const today = new Date();
        const yyyy = today.getFullYear();
        setPeriodoPago(yyyy);  // Se inicializa con el año actual
    }, []);

    const handleCalcularAguinaldos = async () => {
        setOpenBackdrop(true);
        try {
            setBonuses([]);
            var Bonuses = await api.GetChristmasBonusByPeriod(periodoPago);
            console.log(Bonuses);
            setBonuses(Bonuses);

        }
        finally {
            setOpenBackdrop(false);
        }
    };
    const handlePagarSelecionados = () => {
        if (selectedRows.length > 0) {
            setOpenBackdrop(true);
            const bonusItems = bonuses.Items;
            const selectedBonuses = bonusItems.filter(bonus => selectedRows.includes(bonus.IDChristmasBonus));
            const bonusPromises = selectedBonuses.map(bonus => api.PayChristmasBonus(bonus.cedula, periodoPago).then(() => bonus.EstadoPago = 1));
            Promise.all(bonusPromises).then((updatedBonuses) => {
                const updatedBonusItems = bonusItems.map(item => {
                    const updatedItem = updatedBonuses.find(upd => upd.IDChristmasBonus === item.IDChristmasBonus);
                    return updatedItem || item; // Usar el elemento actualizado o el original
                });

                setBonuses({ ...bonuses, Items: updatedBonusItems });
                setSelectedRows([]);
                setOpenBackdrop(false);
            }).catch(error => {
                console.error("Error updating bonuses:", error);
                setOpenBackdrop(false);
            });
        }
    };

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="#ControlPanel" onClick={() => navigateBack()}>
                    ControlPanel
                </Link>
                <Link underline="hover" color="inherit">
                    Pago de Aguinaldos
                </Link>
            </Breadcrumbs>

            <Paper elevation={3} sx={{ textAlign: 'left', padding: 1, margin: 2 }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    {"Pago de Aguinaldos"}
                </Typography>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Typography variant="h6" margin={1} gutterBottom alignContent={'center'}>
                            Aguinaldos
                        </Typography>

                        <Stack direction="row" spacing={1} margin={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    views={['year']}  // Solo permite la selección de año
                                    label="Periodo:"
                                    fullWidth
                                    required
                                    value={dayjs(periodoPago.toString())}
                                    onChange={(newValue) => setPeriodoPago(newValue.format("YYYY"))}
                                    renderInput={(params) => (
                                        <CPTextField
                                            {...params}
                                            required
                                            fullWidth
                                            variant="outline"
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <Button variant='contained' sx={{ margin: 2 }} onClick={handleCalcularAguinaldos}>Calcular Aguinaldos</Button>
                            <Button variant='contained' disabled={selectedRows.length <= 0} sx={{ margin: 2, bgcolor: "green" [700] }} onClick={handlePagarSelecionados}>Pagar</Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        {bonuses ? (
                            <CompanyChristmasBonusGrid bonuses={bonuses.Items} onSelected={(sel) => { setSelectedRows(sel); }} />
                        ) : (
                            <Typography></Typography>
                        )}
                    </Grid>
                </Grid>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Paper>
        </div>
    );
};

export default ChristmasBonus;
