import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import MoreIcon from '@mui/icons-material/More';
import { blue } from '@mui/material/colors';
import { Box, Typography } from '@mui/material';
import { useNavigation } from 'lib/NavigationContext';
import PayBonusPage from "pages/Reports/PayBonusPage"


const CompanyChristmasBonusGrid = ({ bonuses, onSelected }) => {

    const formatter = new Intl.NumberFormat('es-CR', { style: 'currency', currency: 'CRC' });
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const { navigateTo } = useNavigation();

    const openDetail = (row) => {
        // Aquí puedes navegar a otra página o mostrar más detalles del aguinaldo seleccionado
        navigateTo(<PayBonusPage Bonus={row} />, true);
        // console.log(row);
    };


    const columns = () => {
        return [
            { field: 'cedula', headerName: 'Cédula', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 150 },
            { field: 'cargo', headerName: 'Cargo', width: 150 },
            { field: 'Periodo', headerName: 'Periodo', width: 150 },
            {
                field: 'Total', headerName: 'Total', width: 150,
                valueGetter: (params) => formatter.format(params.value || 0)
            },
            {
                field: 'action',
                width: 30,
                type: 'actions',
                getActions: ({ id, row }) => {
                    return [
                        <GridActionsCellItem
                            icon={<MoreIcon sx={{ color: blue[500] }} />}
                            label="Detalle"
                            onClick={() => openDetail(row)}
                        />,
                    ];
                },
            },
            {
                field: 'notification',
                width: 70,
                type: 'actions',
                getActions: ({ id, row }) => {
                    return[
                    <Box> {row.EstadoPago == 0? ""  : <Box sx={{
                        border: 1,
                        borderRadius: "8px",
                        backgroundColor: "green",
                        color: "white",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        fontSize: 11,
                        textAlign: "center",
                        width: 60,
                        fontFamily: "Arial",
                        fontWeight: "fontWeightBold"

                    }}>Pagado</Box>  } </Box>
                    ]
                }
            }
        ];
    };

    // Validación básica para comprobar si los datos son un array
    if (!Array.isArray(bonuses)) {
        return <div>No hay datos</div>;
    }

    return (
        <DataGrid
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
                onSelected(newRowSelectionModel);
            }}
            isRowSelectable={(params) => params.row.EstadoPago == 0}
            rowSelectionModel={rowSelectionModel}
            rows={bonuses}
            columns={columns()}
            getRowId={(row) => row ? row.IDChristmasBonus : 0}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'Periodo', sort: 'desc' }],
                },
            }}
        />
    );
};

// PropTypes para la validación de tipos:
CompanyChristmasBonusGrid.propTypes = {
    bonuses: PropTypes.arrayOf(PropTypes.shape({
        cedula: PropTypes.string.isRequired,
        nombre: PropTypes.string.isRequired,
        cargo: PropTypes.string.isRequired,
        companyID: PropTypes.number.isRequired,
        Desgloce: PropTypes.string.isRequired,
        Periodo: PropTypes.string.isRequired,
        EstadoPago: PropTypes.number.isRequired,
        Total: PropTypes.number.isRequired
    })).isRequired,
    onSelected: PropTypes.func.isRequired
};

export default CompanyChristmasBonusGrid;
