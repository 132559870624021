import React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { DataGrid, GridRowsProp, GridColDef, GridActionsCell, GridActionsCellItem} from '@mui/x-data-grid';
import { List, ListItemAvatar, Avatar, ListItemText, ListItemButton } from '@mui/material';
import { deepPurple, deepOrange } from '@mui/material/colors';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigation} from 'lib/NavigationContext'
import PayRollPage from 'pages/Reports/PayRollPage';
import { getValue } from '@mui/system';
import PayBonusPage from "pages/Reports/PayBonusPage";
import { Box, Typography } from '@mui/material';
import EditVacations from 'pages/vacationsPages/EditVacations';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const dateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric"
  };
const VacationsGrid = ({ employee, vacations, onSelected, onDeleted }) => {

   const formatter = new Intl.NumberFormat('es-CR', { style: 'currency', currency: 'CRC' });

  // Basic validation to check if payments is an array
  const { navigateTo } = useNavigation();
  const deleteAction = (id) => {
    if (onDeleted)
        onDeleted(id);
  }
  const selectAction = (id) => {
    if (onSelected)
        onSelected(id);
  }
  
  const columns = () => {
    const cols = [
      { field: 'FechaInicio', headerName: 'Fecha Inicio', width: 100 },
      { field: 'FechaFinal', headerName: 'Fecha Final', width: 100 },
      { field: 'DiasVacaciones', headerName: 'Dias', width: 100 },
      {
        field: 'Pagados',
        headerName: 'Pagados',
        width: 100,
        renderCell: (params) => (
          params.value ? 
            <CheckIcon style={{ color: 'green' }} /> : 
            <CloseIcon style={{ color: 'red' }} />
        ),
      },
      {
        field: 'actions',
        width: 30,
        type: 'actions',
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              icon={<CreateIcon/>}
              label="Edit"
              onClick={() => {
                navigateTo(<EditVacations employee={employee} oldVacation={row} />)
              }}
            />,
          ];
        },
      },
      {
        field: 'action',
        width: 30,
        type: 'actions',
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon/>}
              label="DELETE"
              onClick={() => deleteAction(id)}
            />,
          ];
        },
      }, 
      
    ];
    
    return cols;

}
    if (!Array.isArray(vacations)) {
      console.log(vacations);
        return <div>Error: vacations is not an array</div>;
    }

    return (
        <DataGrid rows={vacations} columns={columns()}
            getRowId={(row) => { return row ? row.IDVacations : 0; }} sx={{ height: 250 }}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'Fecha', sort: 'desc' }],
                },
            }}
        />
    );

  
};

// PropTypes for type checking
VacationsGrid.propTypes = {
  exceptions: PropTypes.arrayOf(PropTypes.shape({
    IDVacations: PropTypes.string.isRequired,
    companyID: PropTypes.number.isRequired,
    FechaInicio: PropTypes.string.isRequired,
    FechaFinal: PropTypes.string.isRequired,
    DiasVacaciones: PropTypes.number.isRequired,
    Pagados: PropTypes.bool.isRequired,
    
  })).isRequired,
  onSelected: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired
};

export default VacationsGrid;