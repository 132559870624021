import logo from './logo.svg';
import './App.css';
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { useState } from 'react';
import Login from 'pages/Login';
import Registration from 'pages/Registration';
import Home from 'pages/Home';
import { ApiProvider } from 'lib/ApiContext';
import { NavigationProvider } from 'lib/NavigationContext';
import { Box } from '@mui/system';
import imagePath from './images/background.webp';
import logoPath from './images/LogoHiRez.png'



const queryClient = new QueryClient({});

function App() {

  const [loggedIn, setLoggedIn] = useState(false);
  const [session, setSession] = useState({});
  const [user, setUser] = useState({});
  const [needRegister, setNeedRegister] = useState(false);

  const updateLoggedState = (user, se) => {
    setLoggedIn(true);
    setUser(user);
    setSession(se);
  }
  
  const logOut = () => {
    setLoggedIn(false);
    setNeedRegister(false);
    setSession(null);
  }

  return (
    <QueryClientProvider client={queryClient}> 
    <div className="App">
      <header className="App-header">
        <img src={logoPath} alt="Logo" style={{ height: '60px', marginRight: '10px' }} />
      </header>
      {loggedIn ? (
        <ApiProvider user={user} session={session} onLogoutCallback={logOut}>
          <NavigationProvider>
            <Home />
          </NavigationProvider>
        </ApiProvider>
      ) : (
        <Box  sx={{
          backgroundImage: `url(${imagePath})`,
          backgroundSize: 'cover',  // or 'contain' depending on what you need
          backgroundPosition: 'center',
          height: '100vh', // Adjust the height as needed
          // Add other styling as needed
        }}>
        
          { needRegister?
          <Registration onLogin={updateLoggedState} onLogout={logOut}/> 
          :
          <Login onLogin={updateLoggedState} onNeedRegister={() => setNeedRegister(true) } />
          }
        </Box>
      )}
    </div>

    </QueryClientProvider>
  );
}

export default App;
